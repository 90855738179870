/* ======================================================================== */
/* 5. button */
/* ======================================================================== */
.button
	position: relative
	display: inline-block
	+small-caps
	font-weight: bold
	text-align: center
	+trans1
	cursor: pointer
	outline: none
	box-shadow: none
	border-width: 2px
	border-style: solid
	padding: 21px 48px
	border-radius: 64px
	&:focus
		outline: none

.button[data-hover]
	&:before
		content: attr(data-hover)
		display: block
		position: absolute
		top: 50%
		left: 0
		width: 100%
		transform: translateY(100%)
		+trans1
		opacity: 0
		visibility: hidden
	&:hover
		.button__label-hover
			transform: translateY(-100%)
			opacity: 0
			visibility: hidden
		&:before
			transform: translateY(-50%)
			opacity: 1
			visibility: visible

.button__label-hover
	display: block
	transform: translateY(0%)
	+trans1
	opacity: 1
	visibility: visible

.button_icon
	display: inline-flex
	padding: 0
	border: none

.button__label
	display: inline-block
	padding: 21px 48px

.button__icon
	display: inline-flex
	align-items: center
	justify-content: center
	width: 60px
	+trans1
	i
		font-size: 24px

.button_fullwidth
	width: 100%


@media screen and (max-width: 1680px)
	.button
		padding: 14px 32px
	.button__label
		padding: 18px 32px

@media screen and (max-width: $md)
	.button
		padding: 12px 28px
	.button__label
		padding: 12px 28px
	.button__icon
		width: 43px
		i
			font-size: 20px
	.button_icon
		padding: 0
