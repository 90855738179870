/* ======================================================================== */
/* 90. themes */
/* ======================================================================== */
[data-arts-theme-text="light"]
	color: #fff
	.xl
		color: var(--xl-color-light)
	h1, .h1
		color: var(--h1-color-light)
	h2, .h2
		color: var(--h2-color-light)
	h3, .h3
		color: var(--h3-color-light)
	h4, .h4
		color: var(--h4-color-light)
	h5, .h5
		color: var(--h5-color-light)
	h6, .h6
		color: var(--h6-color-light)
	p, .paragraph
		color: var(--paragraph-color-light)
	.figure-post__date
		background-color: #181818
	.post
		blockquote
			&:before
				content: url('../img/general/quote-white.svg')
	blockquote, .blockquote
		color: var(--blockquote-color-light)
		p
			color: var(--blockquote-color-light)
		cite
			color: var(--blockquote-color-light)
	.has-drop-cap:not(:focus):not(.has-drop-cap_split)
		&:first-letter
			color: var(--dropcap-color-light)
	.split-text:not(.js-split-text)
		.has-drop-cap
			> div:first-child
				color: var(--dropcap-color-light)
	.post__tags
		border-color: var(--color-gray-1)
	.tagcloud a:hover, .widget .tagcloud a:hover
		border-color: #fff
	.input-float__input_focused
		border-color: #fff
		+ .input-float__label
			color: #fff
	a
		color: var(--color-gray-1)
		&:hover
			color: #fff
	a.hover-zoom
		&:hover
			.hover-zoom__caption
				color: #fff
	.change-text-hover__line
		background-color: $brown-grey
	.change-text-hover__normal
		color: $brown-grey
	.change-text-hover__hover
		color: #fff
	.arrow
		&:hover
			.arrow__pointer
				background-color: #fff
			.circle
				stroke: #fff
			.arrow__triangle
				border-color: transparent transparent transparent #fff
	.filter__item
		color: #fff
		opacity: .3
		&:hover
			color: #fff
			opacity: 1
	.filter__item_active
		opacity: 1
	.filter__underline
		background-color: #fff
	.figure-project__heading
		color: #fff
	.slider__dot svg .circle
		stroke: #fff
	.slider__dot
		border-color: $dark-2
		&:after
			background-color: $dark-2
	.slider__dot_active
		&:after
			background-color: #fff
	.widgettitle
		color: #fff
	.circle-button__inner
		.svg-circle
			border: 1px solid rgba(255, 255, 255, .3)
		.circle-button__icon
			fill: #fff
	.circle-button__circle
		&:hover
			.svg-circle
				border-color: rgba(255, 255, 255, 1)
	.section-nav-projects__link
		color: #fff
		&:hover
			opacity: 1 !important
			color: var(--color-gray-2) !important
	.figure-icon__wrapper-icon
		&:hover
			border-color: #fff
