/* ======================================================================== */
/* 113. widgetWPML */
/* ======================================================================== */
.widget_icl_lang_sel_widget
	display: inline-block
	margin-bottom: 0
	vertical-align: middle
	font-size: 14px
	.wpml-ls-legacy-dropdown
		width: auto
		border-radius: 2px
		padding: 2px 5px
		a
			border: none
			background: transparent
			&:hover, &:focus
				opacity: 1
	.wpml-ls-legacy-dropdown a:hover, .wpml-ls-legacy-dropdown a:focus, .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a
		background: transparent
	.wpml-ls-sub-menu
		border-top: none
	.wpml-ls-legacy-list-horizontal
		padding: 2px 5px
		.wpml-ls-item
			display: inline-block
			margin-bottom: 0
.lang-switch-no-padding-right
	.widget_icl_lang_sel_widget
		.wpml-ls-legacy-list-horizontal
			padding-right: 0
