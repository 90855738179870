/* ======================================================================== */
/* 80. sliderThemes */
/* ======================================================================== */
.slider[data-arts-theme-text="dark"]
	.slider__counter_current
		color: #fff
	.slider__counter_total
		color: $brown-grey
	a
		color: #fff
