/* ======================================================================== */
/* 84. sliderServices */
/* ======================================================================== */
.slider-services__footer
	max-width: 1180px
	
@media screen and (max-width: 1600px)
	.slider-services__footer
		max-width: 940px

@media screen and (max-width: $xl)
	.slider-services__footer
		max-width: 840px
