/* ======================================================================== */
/* 108. widgetPolylang */
/* ======================================================================== */
.widget_polylang
	display: inline-block
	font-size: 14px
	select
		width: auto
		display: inline-block
		padding: 5px 25px 5px 15px
	ul
		+reset-ul
		li
			display: inline-block
			margin-left: 10px
			margin-right: 10px
			margin-bottom: 0
